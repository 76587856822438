<template>
  <div class="contentwrapper">
      <div class="isFlexGrow" style="position: relative;">
        <div class="contentsroll">
          <!-- content: start -->
          <div class="p11 tsk bluebg11 white mt11" v-if="loading">Opening Share</div>

          <div class="p11 isFlex bluebg2 isFlexAlign" v-if="!loading">
            <div class="isFlexGrow">You have been sent a mind map</div>
            <div><b-button @click="openMindMap" type="is-info">Open</b-button></div>
            <div></div>
          </div>
          <!-- content: end -->
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import ModalMindMap from '../modals/ModalMindMap.vue';

export default {
  mixins: [Utils],
  name: 'blank',
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      area: this.$route.params.area,
    }
  },
  created() {
    if (this.area === 'mindmap') {
      this.openMindMap();
    }
  },
  methods: {
    openMindMap() {
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalMindMap,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: { _id: this.id },
        },
        events: {
          dataFromModal: value => {}
        }
      });
      this.loading = false;
    }
  }
}
</script>